import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const HomePage = React.lazy(() => import("pages/Home"));

const MainRoutes = () => {
  return (
    <React.Suspense fallback={<span>Loading</span>}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  );
};

export default MainRoutes;
