import './App.css'
import MainRoutes from "router/MainRoutes"
import { ToastContainer } from "react-toastify"

function App() {
  return (
    <>
      <ToastContainer />
      <MainRoutes/>
    </>
  )
}

export default App
