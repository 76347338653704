import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import en from "./en.json"
import vi from "./vi.json"

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],
      lookupQuerystring: "lng",
    },
    fallbackLng: "vi",
    resources: {
      en: {
        translation: en
      },
      vi: {
        translation: vi
      }
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
